.modal {
  --jello-modal-width: 60rem; /* Default width */
  z-index: 310;
  position: fixed;
}

@media only screen and (max-width: 65rem) {
  .modal {
    --jello-modal-width: 42rem; /* Adjusted width for smaller screens */
  }
}

@media only screen and (min-width: 75rem) {
  .modal {
    --jello-modal-width: 60rem;
  }
}

.menu-section {
  max-height: 548px;
  overflow: auto;
}

.bottomBox {
  --jello-box-padding: 16px 0 0 0;
  top: 15px;
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.whatsNewMenu {
  cursor: pointer;
}

.sections {
  border-right: 1px solid var(--jello-color-border-base);
  padding-right: 10px;
}

.modalWrapper {
  padding: var(--jello-spacing-small);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bodyWrapper {
  display: flex;
  flex-wrap: wrap;
}

.footerWrapper {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 56px;
}

.bottomBoxWrapper {
  background-color: var(--jello-color-background-base);
  padding-bottom: 23px;
  padding-top: 0;
  position: fixed;
  bottom: 1px;
  right: var(--jello-spacing-extra-large);
  left: var(--jello-spacing-extra-large);
}
