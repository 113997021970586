.toolbarPatientInfoWrapper {
  display: flex;
  align-items: flex-start;
}

.toolbarDivider {
  width: 1px;
  height: 40px;
  background-color: var(--jello-color-background-alternative);
  margin: 0 16px;
  margin-top: 4px;
}
