.menuContainer {
  height: 548px;
  overflow: auto;
}

.section {
  clear: both;
  color: var(--jello-color-interactive-base);
  cursor: pointer;
  display: block;
  margin-top: 15px;
  margin-bottom: 15px;
  &:hover {
    color: var(--jello-color-interactive-active);
  }
}
.sectionActive {
  color: var(--jello-color-interactive-active);
}
