.hiAndLoValues {
  display: flex;
  width: 456px;
  height: 128px;
  flex-direction: column;
  align-items: flex-start;
  position: fixed;
  right: 16px;
  top: 18px;
  z-index: var(--jello-z-index-notification);
  animation-duration: 3s;
  animation-name: slidein;
}

@keyframes slidein {
  from {
    right: -100%;
  }

  to {
    right: 16px;
  }
}
