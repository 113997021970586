.mediaContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 400px;
  margin: 0 auto;
}
.mediaBlock {
  max-width: 100%;
  max-height: 400px;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.lineSpace {
  margin: 16px 0px;
}

.styledUL {
  padding: 0px 18px;
}

.fallbackBlock {
  width: 100%;
  height: auto;
  background-color: var(--jello-color-background-neutral);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--jello-interactive-selected, #ddd);
  border-radius: 0.5rem;
  padding: 1rem;
}

.fallbackImage {
  width: 1.25rem;
  height: 1.25rem;
  margin-bottom: 0.75rem;
}

.callout {
  background: var(--jello-color-background-alternative);
  padding: var(--jello-spacing-small);
  border-radius: var(--jello-border-radius-medium);
}
