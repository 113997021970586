.patientSecondaryDataWrapper {
  display: flex;
  gap: 24px;
  align-items: flex-start;
  padding-top: 4px;
}

.patientTitleAndInfoWrapper {
  display: flex;
  flex-direction: column;
}

.patientDataTitle {
  color: var(--jello-color-foreground-secondary);
}

.patientMainDataWrapper {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  min-height: 48px;
  padding-top: 2px;
}

.patientMainDataText {
  color: var(--jello-color-foreground-base);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 320px;
}

@media only screen and (max-width: 1311px) {
  .patientMainDataText {
    max-width: 205px;
  }
}
