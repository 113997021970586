.contentContainer {
  height: 548px;
  overflow: auto;
  overflow-x: hidden;
  padding: 0 20px 0 10px;

  display: inline-block;

  .icon {
    color: var(--jello-color-foreground-highlight-base);
  }
}
