.iconsSection {
    display: flex;
    flex-direction: column;
    gap: var(--jello-spacing-large);
    margin-bottom: var(--jello-spacing-huge);
}

.iconsList {
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(9rem, 100%), 1fr));
    gap: var(--jello-spacing-large) var(--jello-spacing-extra-large);
}

.iconListItem {
    list-style: none;
    display: flex;
    gap: var(--jello-spacing-medium);
    align-items: center;
}

.iconListItem jello-icon {
    display: inline-block;
    flex-shrink: 0;
}

.iconListItem jello-text {
    min-width: 0;
    word-wrap: break-word;
}
