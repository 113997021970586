.heightWrapper {
    height: 100%;
}

.modalContentLayout {
    display: flex;
    flex-direction: column;
    gap: var(--jello-spacing-medium);
    min-height: 100%;
}

.columnsSeparator {
    border: none;
    margin: 0;
    background-color: var(--jello-color-border-base);
    flex-basis: var(--jello-border-width-absolute-small);
    flex-shrink: 0;
}

.menuColumn{
    list-style: none;
    margin: 0;
    padding: 0;
}

.menuColumnItem {
    padding: var(--jello-spacing-medium) 0;
}

@media only screen and (min-width: 45rem) {
    .heightWrapper {
        --DIALOG_MIN_HEIGHT: 38rem;

        height: var(--DIALOG_MIN_HEIGHT);
    }

    .modalContentLayout {
        flex-direction: row;
        gap: var(--jello-spacing-huge);
    }

    .columnsSeparator {
        height: auto;
    }

    .menuColumn{
        --MENU_WIDTH: 9rem;

        flex-basis: var(--MENU_WIDTH);
        flex-shrink: 0;
        align-self: start;
    }

    @media (min-height: 38rem) {
        .menuColumn{
            position: sticky;
            top: 0;
        }

        .columnsSeparator {
            position: sticky;
            top: 0;
            height: var(--DIALOG_MIN_HEIGHT);
        }
    }
}

