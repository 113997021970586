.list {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  margin: 4px 16px;
}

.externalLink {
  text-decoration: none;
  color: var(--jello-color-foreground-highlight-base);
}

.liElement {
  list-style: none;
}

.popoverIndex {
  z-index: -1;
}

.listItem {
  list-style: none;
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  padding: 12px 0;
}
